import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import pic1 from '../../assets/jpg/feat01.jpg';
import pic2 from '../../assets/jpg/feat02.jpg';
import pic3 from '../../assets/jpg/feat03.jpg';

const Plan = () => {
  const [activeSlide, setActiveSlide] = useState('');

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    vertical: true,
    verticalSwiping: true,
    beforeChange: (current, next) => handleBeforeChange(next),
  };

  const handleBeforeChange = (next) => {
    const slides = ['slide-01', 'slide-02', 'slide-03'];
    const nextSlideClass = slides[next % slides.length];
    setActiveSlide(nextSlideClass);
  };

  useEffect(() => {
    // Initial check
    const activeElement = document.querySelector('.slick-active.slick-current');
    if (activeElement) {
      if (activeElement.querySelector('.slide-01')) {
        setActiveSlide('slide-01');
      } else if (activeElement.querySelector('.slide-02')) {
        setActiveSlide('slide-02');
      } else if (activeElement.querySelector('.slide-03')) {
        setActiveSlide('slide-03');
      }
    }
  }, []);

  return (
    <section id='plan'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-10'>
            <Slider {...settings}>
              <div className='slide-01'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='w-80 animate__animated animate__fadeInUp animate__delay-1s'>
                      <div className='s-60'></div>
                      <h3 className='title c-theme'>Навчання</h3>
                      <div className='s-20'></div>
                      <p className='title-mini'>
                        Онлайн-лекції та практичні завдання від провідних
                        експертів і діючих підприємців
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 tc'>
                    <div className='w-50 tc py-5'>
                      <img
                        src={pic1}
                        width={320}
                        height={460}
                        className='obfc r-24'
                        alt='Навчання'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='slide-02'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='w-80 animate__animated animate__fadeInUp animate__delay-2s'>
                      <div className='s-60'></div>
                      <h3 className='title c-theme'>Робота з наставниками</h3>
                      <div className='s-20'></div>
                      <p className='title-mini'>
                        Учасники об'єднаються в команди та отримають можливість
                        працювати над проектом разом із кваліфікованим трекером
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 tc'>
                    <div className='w-50 tc py-5'>
                      <img
                        src={pic2}
                        width={320}
                        height={460}
                        className='obfc r-24'
                        alt='Навчання'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='slide-03'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='w-80 animate__animated animate__fadeInUp animate__delay-3s'>
                      <div className='s-60'></div>
                      <h3 className='title c-theme'>Демо-день</h3>
                      <div className='s-20'></div>
                      <p className='title-mini'>
                        Найкращі команди представлять проекти на Демо-дні у
                        Києві
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6 tc'>
                    <div className='w-50 tc py-5'>
                      <img
                        src={pic3}
                        width={320}
                        height={460}
                        className='obfc r-24'
                        alt='Навчання'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className='col-lg-2 tr'>
            <div className='calendar'>
              <div className='list'>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-01' ? 'active' : 'c-grey'
                  }`}
                >
                  Червень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-01' ? 'active' : 'c-grey'
                  }`}
                >
                  Липень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-01' ? 'active' : 'c-grey'
                  }`}
                >
                  Серпень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Вересень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Жовтень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Листопад
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Грудень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Січень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Лютий
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Березень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-02' ? 'active' : 'c-grey'
                  }`}
                >
                  Квітень
                </p>
                <p
                  className={`title-mini ${
                    activeSlide === 'slide-03' ? 'active' : 'c-grey'
                  }`}
                >
                  Травень
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Plan };
