import React from 'react';
import { Nav } from '../../components/Nav';
import { FaArrowRight } from 'react-icons/fa';
import Marquee from 'react-fast-marquee';

import photo1 from '../../assets/png/001.png';
import photo2 from '../../assets/png/002.png';
import photo3 from '../../assets/png/003.png';

const Intro = () => {
  return (
    <>
      <section id='top'>
        <div className='container'>
          <div className='s-10'></div>
          <Nav />
          <div className='s-100'></div>
          <div className='row'>
            <div className='col-lg-6'>
              <h3 className='title-intro c-white h-10 flex jcfe aic'>
                РОЗПОЧАТИ
              </h3>
            </div>
            <div className='col-lg-6'>
              <p className='c-white h-10 flex aic title-sub w-90'>
                На нашій програмі ти знайдеш нових друзів, втілиш свої ідеї в
                життя і отримаєш бонуси при вступі до вузу
              </p>
            </div>
          </div>
          <div className='tc'>
            <h3 className='title-intro c-white'>СТАРТАП</h3>
            <div className='s-40'></div>
          </div>
          <div className='tc box'>
            <div className='s-100'></div>
            <div className='item item-1'>
              <div className='flex jcsb aic'>
                <h3 className='title-intro upp c-white'>КРУТО</h3>
                <div className='flex aic canvas'>
                  <img
                    src={photo1}
                    width={64}
                    height={64}
                    alt={'Василь Лисенко'}
                  />
                  <span
                    className='c-white tl'
                    style={{ padding: '0 24px 0 16px', lineHeight: 1.2 }}
                  >
                    Василь
                    <br />
                    Лисенко
                  </span>
                </div>
              </div>
            </div>
            <div className='item item-2'>
              <div className='flex jcsb aic'>
                <h3 className='title-intro upp c-white'>Класно</h3>
                <div className='flex aic canvas'>
                  <img
                    src={photo2}
                    width={64}
                    height={64}
                    alt={'Олена Резнiкова'}
                  />
                  <span
                    className='c-white tl'
                    style={{ padding: '0 24px 0 16px', lineHeight: 1.2 }}
                  >
                    Олена
                    <br />
                    Резнiкова
                  </span>
                </div>
              </div>
            </div>
            <div className='item item-3'>
              <div className='flex jcsb aic'>
                <h3 className='title-intro upp c-white'>Легко</h3>
                <div className='flex aic canvas'>
                  <img
                    src={photo3}
                    width={64}
                    height={64}
                    alt={'Петро Мовчан'}
                  />
                  <span
                    className='c-white tl'
                    style={{ padding: '0 24px 0 16px', lineHeight: 1.2 }}
                  >
                    Петро
                    <br />
                    Мовчан
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='tc'>
            <a href='/' className='button upp'>
              Зареєструватися
            </a>
          </div>
          <div className='s-50'></div>
        </div>
        <Marquee direction='right'>
          <div className='flex jcsa aic c-white'>
            <span className='c-white px-4'>РЕЄСТРАЦІЯ ВІДКРИТА</span>
            <FaArrowRight color={'white'} />
            <span className='c-white px-4'>БЕЗКОШТОВНО</span>
            <FaArrowRight color={'white'} />
          </div>
          <div className='flex jcsa aic c-white'>
            <span className='c-white px-4'>РЕЄСТРАЦІЯ ВІДКРИТА</span>
            <FaArrowRight color={'white'} />
            <span className='c-white px-4'>БЕЗКОШТОВНО</span>
            <FaArrowRight color={'white'} />
          </div>
          <div className='flex jcsa aic c-white'>
            <span className='c-white px-4'>РЕЄСТРАЦІЯ ВІДКРИТА</span>
            <FaArrowRight color={'white'} />
            <span className='c-white px-4'>БЕЗКОШТОВНО</span>
            <FaArrowRight color={'white'} />
          </div>
        </Marquee>
        <div className='s-40'></div>
        <div className='s-100'></div>
      </section>
      <div class='background'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export { Intro };
