const analitics = [
  {
    id: 1,
    title: 'Генерація ідей',
    text: 'Дізнаєшся, де шукати креатив і як вибирати ідею для бізнесу',
    meta: 'креатив',
  },
  {
    id: 2,
    title: 'Конкуренти',
    text: 'Перевіриш, які схожі продукти вже існують. Дізнаєшся, хто такі прямі і непрямі конкуренти',
    meta: 'суперники',
  },
  {
    id: 3,
    title: '',
    text: '',
    meta: '',
  },
  {
    id: 4,
    title: 'Цільова аудиторія',
    text: 'Визначиш свого потенційного покупця. На практиці розбереш різні портрети клієнтів',
    meta: 'користувачі',
  },
  {
    id: 5,
    title: 'Перевірка гіпотез',
    text: 'Перевіриш гіпотези щодо потреб клієнтів, дізнаєшся, що таке Customer Development',
    meta: 'дослідження',
  },
  {
    id: 6,
    title: 'Ціннісна пропозиція',
    text: 'Сформулюєш цінність свого продукту',
    meta: 'унікальність',
  },
  {
    id: 7,
    title: '',
    text: '',
    meta: '',
  },
  {
    id: 8,
    title: 'Бізнес-модель',
    text: 'Дізнаєшся, які бувають моделі монетизації, і вибереш підходящу',
    meta: 'продукт',
  },
  {
    id: 9,
    title: 'Оцінка ринку',
    text: 'Оціниш потенціал ринку і розрахуєш потенційний дохід твоєї компанії',
    meta: 'аналіз',
  },
];

export default analitics;
