import React from 'react';
import { Intro } from './parts/Intro';
import { Features } from './parts/Features';
import { Footer } from '../components/Footer';
import { PopUp } from './parts/PopUp';
import { Plan } from './parts/Plan';
import { Winners } from './parts/Winners';
import { Program } from './parts/Program';
import { FAQ } from './parts/FAQ';
import { Partners } from './parts/Partners';
import { CTA } from './parts/CTA';

const Home = () => {
  return (
    <>
      <PopUp />
      <Intro />
      <Features />
      <Plan />
      <Winners />
      <Program />
      <FAQ />
      <Partners />
      <CTA />
      <Footer />
    </>
  );
};

export { Home };
