import React from 'react';

const Footer = () => {
  return (
    <footer id='contact'>
      <div className='container'>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <p className='c-dark fw-400 tl w-100'>EasyStartup © 2024</p>
            <p className='c-dark fw-400 tl w-100'>
              Реєстраційний номер: 1045678.
            </p>
          </div>
          <div className='col-lg-2'></div>
          <div className='col-lg-4'>
            <p className='c-dark fw-400 tl w-100'>
              <a href='mailto:admin@easystartup.com.ua' className='c-dark'>
                admin@easystartup.com.ua
              </a>
            </p>
            <p className='c-dark fw-400 tl w-100'>
              Гаряча лінія:{' '}
              <a href='tel:+380502045687' className='c-dark'>
                +380 50 204 5687
              </a>
            </p>
          </div>
        </div>
        <div className='s-40'></div>
      </div>
    </footer>
  );
};

export { Footer };
