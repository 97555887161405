import React from 'react';
import pic from '../../assets/jpg/startup-new.jpg';

const CTA = () => {
  return (
    <section id='cta'>
      <div className='s-40'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 flex aic'>
            <div className='p-5'>
              <h2 className='title-cta c-white px-3'>
                Запускай бізнес
                <br />
                вже зараз!
              </h2>
              <div className='s-30'></div>
              <div className='px-3'>
                <a href='/' className='button'>
                  Зареєструватися
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <img src={pic} alt='' />
          </div>
        </div>
      </div>
      <div className='s-80'></div>
    </section>
  );
};

export { CTA };
