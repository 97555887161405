import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';

const FAQ = () => {
  return (
    <section id='faq'>
      <div className='s-100'></div>
      <div className='container'>
        <h3 className='title c-theme'>Питання та відповіді</h3>
        <MDBContainer className='mt-5'>
          <MDBAccordion alwaysOpen initialActive={1}>
            <MDBAccordionItem
              collapseId={1}
              headerTitle='Що потрібно для участі?'
            >
              Бути школярем або студентом. Пройти реєстрацію на сайті шкільного
              акселератора.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle='Де проводиться програма?'
            >
              Програма проходить онлайн — на окремій цифровій платформі
              easyStartup.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle='Скільки коштує участь?'
            >
              Участь у програмі безкоштовна.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={4}
              headerTitle='Що входить до програми?'
            >
              Програма складається з 3 етапів: індивідуального онлайн-навчання
              та практичних завдань, роботи команди з бізнес-наставником,
              звітних заходів.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={5}
              headerTitle='Скільки часу потрібно виділяти на навчання?'
            >
              Освітня програма складається з 15 онлайн-модулів і практичних
              завдань після кожного відео. Проходження одного модуля займає до 3
              годин. Учасникам важливо розуміти, що для успішного запуску
              бізнесу необхідно присвячувати своєму проєкту більшу частину
              вільного часу.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={6}
              headerTitle='Що отримають переможці?'
            >
              Переможці отримають гранти від easyStartup та IT-Паросток на
              розвиток проєкту.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={7}
              headerTitle='Чи можна брати участь зі своєю командою?'
            >
              Так, звичайно. Важливо, щоб усі члени команди були школярами або
              студентами. Їм також необхідно пройти реєстрацію в шкільному
              акселераторі. Максимальна кількість учасників у команді — 8 осіб.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={8}
              headerTitle='Чи можна запросити друзів, які не є школярами або студентами?'
            >
              Ні, в шкільному акселераторі можуть брати участь тільки школярі
              або студенти коледжів/технікумів/інших навчальних закладів.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={9}
              headerTitle='Де можна дізнатися подробиці?'
            >
              Підписуйся на нашу спільноту в Telegram і залишайся в курсі
              найактуальніших новин.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={10}
              headerTitle="Залишилися питання – з ким я можу зв'язатися?"
            >
              Якщо залишилися питання, напиши нам на пошту
              <a href='mailto:admin@easystartup.com.ua'>
                admin@easystartup.com.ua
              </a>
              або за телефоном <a href='tel:+380502045687'>+380 50 204 5687</a>
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
        <div className='s-100'></div>
      </div>
    </section>
  );
};
export { FAQ };
