import React from 'react';
import winners from '../../data/winners';

const Winners = () => {
  return (
    <section id='winners'>
      <div className='container'>
        <div className='s-100'></div>
        <h2 className='title c-white'>Фіналісти 2023</h2>
        <div className='s-40'></div>
        <h3 className='title-sub c-white w-50 lh-175'>
          Учасники пройшли всі етапи шкільного акселератора і тепер змагаються
          за інвестиції у тв-шоу «Гонка стартапів»
        </h3>
        <div className='s-40'></div>
        <a href='/' className='button'>
          Дивитися шоу
        </a>
        <div className='s-100'></div>
        <div className='row'>
          {winners.map((e) => (
            <div className='col-lg-4 p-4'>
              <div className={`winner-${e.id}`} key={e.id}>
                <div className='tc p-3'>
                  <div className='px-5 py-4'>
                    <img
                      src={e.pic}
                      alt={e.name}
                      width={'100%'}
                      height={'auto'}
                      className='obfc r-8'
                    />
                  </div>
                  <h4 className='title-mini c-white'>{e.name}</h4>
                  <h5 className='title-sub c-white py-2'>{e.title}</h5>
                  <p className='c-white px-3'>{e.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='s-100'></div>
      </div>
    </section>
  );
};

export { Winners };
