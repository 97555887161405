import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import analitics from '../../data/analitics';
import start from '../../data/start';

const Program = () => {
  return (
    <section id='program'>
      <div className='s-100'></div>
      <div className='container'>
        <h3 className='title c-theme'>Програма онлайн-курсу</h3>
        <div className='s-80'></div>
        <Tabs defaultActiveKey='analitics' id='programs' className='mb-3'>
          <Tab eventKey='analitics' title='Аналіз'>
            <div className='row'>
              {analitics.map((el) => (
                <div className='col-lg-4 px-3' key={el.id}>
                  <div className='card shadow-sm analitics p-5 my-3'>
                    <h3 className='title-mini c-theme fw-600'>{el.title}</h3>
                    <p className='c-dark my-2'>{el.text}</p>
                    <span className='meta'>{el.meta}</span>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey='start' title='Запуск'>
            <div className='row'>
              {start.map((el) => (
                <div className='col-lg-4 px-3' key={el.id}>
                  <div className='card shadow-sm start p-5 my-3'>
                    <h3 className='title-mini c-theme fw-600'>{el.title}</h3>
                    <p className='c-dark my-2'>{el.text}</p>
                    <span className='meta'>{el.meta}</span>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Program };
