import React, { useState, useEffect } from 'react';
import logo from '../logo-y.png';

const Nav = () => {
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (!opened) {
      document.body.classList.add('menu-active');
    } else {
      document.body.classList.remove('menu-active');
    }
  }, [opened]);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  return (
    <header className='header'>
      <div className='container'>
        <div className='flex jcsb aic'>
          <h1 id='logo'>
            <a href='/' aria-label='Logotype'>
              <img src={logo} width={197} height={56} alt='easystartup' />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={toggleMenu}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a
              href='#program'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Програма
            </a>
            <a
              href='#faq'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Питання
            </a>
            <a
              href='#community'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Спільнота
            </a>
            <a
              href='#cases'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Преференції
            </a>
            <a
              href='#interns'
              className='mx-3 px-1'
              onClick={() => setOpened(!opened)}
            >
              Випускники
            </a>
          </div>
          <a href='/' className='button top'>
            Увійти
          </a>
        </div>
      </div>
    </header>
  );
};

export { Nav };
