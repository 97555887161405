import React from 'react';

const Features = () => {
  return (
    <section id='features'>
      <div className='s-100'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 animate__animated animate__fadeInUp tc'>
            <h2 className='title-intro c-theme tl'>75 000+</h2>
            <p className='sub-counter tl'>учасників Акселератора</p>
          </div>
          <div className='col-lg-4 animate__animated animate__fadeInUp animate__delay-1s tc'>
            <h2 className='title-intro c-theme'>1400+</h2>
            <p className='sub-counter tc'>проектів створено</p>
          </div>
          <div className='col-lg-4 animate__animated animate__fadeInUp animate__delay-2s tc'>
            <h2 className='title-intro c-theme'>10</h2>
            <p className='sub-counter w-80 tr'>
              балів до ЗНО для переможців і призерів
            </p>
          </div>
        </div>
      </div>
      <div className='s-50'></div>
      <div className='bg-1 border flex aic'>
        <h3 className='title w-50 px-5'>Реалізуй ідеї</h3>
        <p className='title-sub w-50 px-5'>
          У тебе є можливість втілити в життя свою ідею з будь-якої сфери
        </p>
      </div>
      <div className='bg-2 border flex aic'>
        <h3 className='title w-50 px-5'>Збери команду</h3>
        <p className='title-sub w-50 px-5'>
          Знайди друзів та вдосконалюй свої навички в рамках спільної роботи над
          проєктом
        </p>
      </div>
      <div className='bg-3 border flex aic'>
        <h3 className='title w-50 px-5'>Отримай інвестиції</h3>
        <p className='title-sub w-50 px-5'>
          Покажи свій проєкт професійним інвесторам на Демо-дні та отримай гроші
          на масштабування бізнесу
        </p>
      </div>
      <div className='bg-4 border flex aic'>
        <h3 className='title w-50 px-5'>Отримай преференції</h3>
        <p className='title-sub w-50 px-5'>
          Отримай можливість вступити до вишу без іспитів. Подробиці.
        </p>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Features };
