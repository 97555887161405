import pic1 from '../assets/png/win1.png';
import pic2 from '../assets/png/win2.png';
import pic3 from '../assets/png/win3.png';

const winners = [
  {
    id: 1,
    name: 'Олексій Гончаренко',
    title: 'SmartShopTech',
    pic: pic1,
    desc: 'Умні пристрої для магазину: візки, кошики, рішення для збирачів',
  },
  {
    id: 2,
    name: 'Катерина Шевченко',
    title: 'ParkAR',
    pic: pic2,
    desc: 'Мобільний додаток з доповненою реальністю для відвідувачів парків',
  },
  {
    id: 3,
    name: 'Андрій Мельник',
    title: 'DefenseAI',
    pic: pic3,
    desc: "Моніторинг військових об'єктів у режимі реального часу з ШІ",
  },
];

export default winners;
