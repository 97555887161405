const start = [
  {
    id: 1,
    title: 'Ресурси',
    text: 'Складеш карту дій і зрозумієш, які ресурси потрібні твоєму проекту і де їх шукати',
    meta: 'гроші',
  },
  {
    id: 2,
    title: 'Робота в команді',
    text: 'Зрозумієш, які ролі в проекті існують і як можна мотивувати співробітників',
    meta: 'софт скіли',
  },
  {
    id: 3,
    title: 'Планування',
    text: 'Розподілиш зони відповідальності всередині команди і складеш чіткий план дій на найближчий час',
    meta: 'тайм-менеджмент',
  },
  {
    id: 4,
    title: 'Фінанси',
    text: 'Зрозумієш, як розрахувати прибуток і планувати витрати',
    meta: 'юнит-економіка',
  },
  {
    id: 5,
    title: 'MVP',
    text: 'Дізнаєшся, що таке «мінімально життєздатний продукт» і отримаєш інструкції зі створення сайту, чат-бота або додатку',
    meta: 'продажі',
  },
  {
    id: 6,
    title: '',
    text: '',
    meta: '',
  },
  {
    id: 7,
    title: 'Канали просування',
    text: 'Дізнаєшся про основи маркетингу і канали просування, спробуєш сам просунути свій продукт',
    meta: 'маркетинг',
  },
  {
    id: 8,
    title: 'Інвестиції',
    text: 'Дізнаєшся, де знайти кошти на розвиток проекту',
    meta: 'гроші',
  },
  {
    id: 9,
    title: 'Публічні виступи',
    text: "Навчишся створювати переконливі та запам'ятовувані презентації і демонструвати їх",
    meta: 'демо-день',
  },
];

export default start;
