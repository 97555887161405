import React from 'react';
import partners from '../../data/partners';

const Partners = () => {
  return (
    <section id='clients'>
      <div className='container'>
        <h3 className='title c-theme'>Партнери акселератора</h3>
        <div className='s-40'></div>
        <div className='row'>
          {partners.map((item) => (
            <div className='col-lg-3' id={item.id}>
              <div className='flex jcc aic border p-5 mx-2 my-3 h-30'>
                <div>
                  <img
                    src={item.pic}
                    alt={item.name}
                    width={100}
                    height={100}
                    className='obfc greyscale'
                  />
                  <div className='s-10'></div>
                  <p className='c-medium'>{item.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Partners };
