const partners = [
  {
    id: 1,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/znzozkptgbkm7znd8zkj',
    name: 'Divvy',
  },
  {
    id: 2,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1488210387/ai2wjnegkzfrbwoyq1y7.png',
    name: 'Drizly',
  },
  {
    id: 3,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v3yrycagr6k9dxyvqee3',
    name: 'Qubit',
  },
  {
    id: 4,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ka9pebrw9g9nfm79peu5',
    name: 'Robin Healthcare',
  },
  {
    id: 5,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/mmxijsr3envpbt1relql',
    name: 'Yellow',
  },
  {
    id: 6,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ibpvfub5v9xi19vaz7xd',
    name: 'Virgin Orbit',
  },
  {
    id: 7,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/vhulccqoq21hfu3ph2l2',
    name: 'Global Blood Therapeutics',
  },
  {
    id: 8,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1433832473/k0jlerg9gein3jcu5owg.png',
    name: 'Clovia',
  },
  {
    id: 9,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1490263572/laoxd8xrjz5g4ejjbx1w.png',
    name: 'Tianyancha',
  },
  {
    id: 10,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1455902561/vw80giyhu78vdbihjlxg.png',
    name: 'Managed by Q',
  },
  {
    id: 11,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/job3znxob5klkejr3tqs',
    name: 'Quibi',
  },
  {
    id: 12,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/kzishfzwuzeh6ualn6ds',
    name: 'Magnitude Software',
  },
  {
    id: 13,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1479457992/vwnzrfs76oyy3imo3q9y.png',
    name: 'Tapzo',
  },
  {
    id: 14,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1504663535/ho1so4lfyliccqhxpnqc.jpg',
    name: 'MoneyOnMobile',
  },
  {
    id: 15,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1429918183/jrko14o3lc1kibn1txiw.png',
    name: 'SkipTheDishes',
  },
  {
    id: 16,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1465845971/exvnn37wdcu8jbcbxn6j.png',
    name: '4C Insights',
  },
  {
    id: 17,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ba4d5dc0ee03179f525a',
    name: 'Lilith Games',
  },
  {
    id: 18,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1425049899/x3ktk9tmeawhewdvejtt.png',
    name: 'TinyOwl',
  },
  {
    id: 19,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397188496/11470dfe8ce4bed2fed6549280ae3443.png',
    name: 'Zepo',
  },
  {
    id: 20,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/o8bufripprgvuf6p4wpu',
    name: 'Fingerlix',
  },
  {
    id: 21,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hnfnsb6z6dgv71dzpdqf',
    name: 'MyoKardia',
  },
  {
    id: 22,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1452704247/sjo8ocekymllsyp7codi.png',
    name: 'Tegile Systems',
  },
  {
    id: 23,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1489046450/qorhzqmyigug2hiqsu4g.png',
    name: 'Chariot',
  },
  {
    id: 24,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1453348127/ndq3a5gznutfosuzvevh.png',
    name: 'Just Buy Live',
  },
  {
    id: 25,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/pshzxtojsz7xfmqa0ubl',
    name: 'Olive',
  },
  {
    id: 26,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397753519/afa2108416a569d27663de5a4f4659de.png',
    name: 'Opus Bank',
  },
  {
    id: 27,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/jwjkslfqbjed4d5jqpyj',
    name: 'CECport',
  },
  {
    id: 28,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1457031109/hfyjmz5umsbbrvogdmmx.png',
    name: 'ZopNow',
  },
  {
    id: 29,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/eudjuyzlkdxvjij35k1n',
    name: 'NOME',
  },
  {
    id: 30,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1506351615/u69gh2mhfn8mgjckkray.png',
    name: 'RenRenChe',
  },
  {
    id: 31,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/oyjxzlthqahrwe0s0xmq',
    name: 'Flywheel Sports',
  },
  {
    id: 32,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/oxrxulbetykqjbwjr0fn',
    name: 'BlockFi',
  },
  {
    id: 33,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/y5yfdc3qqnnvokltocz4',
    name: 'Kreditech',
  },
  {
    id: 34,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/wprk3hdozijrqq3mumf8',
    name: 'Kenoby',
  },
  {
    id: 35,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1428381979/lbz0seyasioczqdd0avv.png',
    name: 'Matahari Mall',
  },
  {
    id: 36,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ljsqlpgspy1pcq0q90wl',
    name: 'iKcon',
  },
  {
    id: 37,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/mivmpu8pyuqdpqcw80gq',
    name: 'Fast',
  },
  {
    id: 38,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/qgzfqmxqdd37dkwxgsgr',
    name: 'Easy Taxi',
  },
  {
    id: 39,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/c7me4anez1os1wmm37cu',
    name: 'Deliverr',
  },
  {
    id: 40,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1426764544/emlugtht3rwosvkxrsna.jpg',
    name: 'Accriva Diagnostics',
  },
  {
    id: 41,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/q5hhkggjz4zy9t0afqua',
    name: 'Scotty',
  },
  {
    id: 42,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/uxsjaau3hhy3mcxv5jxk',
    name: 'True Health Group',
  },
  {
    id: 43,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397188864/f2d1225fff15d04bda8e2d6259d773ce.png',
    name: 'Kapost',
  },
  {
    id: 44,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/fuanoxf1onkaatich5jh',
    name: 'Cura Partners',
  },
  {
    id: 45,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/fjagnposobgeoalxsi4g',
    name: 'Rakuten Americas',
  },
  {
    id: 46,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hag25wcovg484zemwrfx',
    name: 'ODX Pte. Ltd',
  },
  {
    id: 47,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/u1u9hijyv88vsq4ofu5y',
    name: 'Knotel',
  },
  {
    id: 48,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1403552469/mavgtbtuqcyti1szfjqh.jpg',
    name: 'Chloe + Isabel',
  },
  {
    id: 49,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/dfpkrbvlw7wg8dwrm1j5',
    name: 'Auctionata',
  },
  {
    id: 50,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/uvkxt4u7s1grhqhw5kgo',
    name: 'Vlocity',
  },
  {
    id: 51,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1409316496/ac5i6wg65mulj3fnz2vs.jpg',
    name: 'Rightside',
  },
  {
    id: 52,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/vao8yttggbtuvstgefgg',
    name: 'Hostmaker',
  },
  {
    id: 53,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/96e3b5d8310c42d7c4a2',
    name: 'Cyclone',
  },
  {
    id: 54,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/qx5y4xldf4s9wspanzci',
    name: 'Sandbox Agency',
  },
  {
    id: 55,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/e8hnz2botweufmfxeus5',
    name: 'Aprende Institute',
  },
  {
    id: 56,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/gdakgrf5ecescvflr818',
    name: 'Trillium Secure',
  },
  {
    id: 57,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1489816327/ms4gfr06fjwumjfpei10.png',
    name: 'Simtoo',
  },
  {
    id: 58,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/psxvzfeckclazzdsyoiq',
    name: 'Good Night Medical',
  },
  {
    id: 59,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/bzgcu4hvcbbeuo4cb968',
    name: 'QuizUp',
  },
  {
    id: 60,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1422419482/k26przrz0aruul2nmpcd.png',
    name: 'Advance Health',
  },
  {
    id: 61,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1482877719/getzgxj7jtwyv2bp83st.png',
    name: 'DAYLI Financial Group',
  },
  {
    id: 62,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/wzjj0ce0tgqmmt7iczqt',
    name: 'Kettlebell Kitchen',
  },
  {
    id: 63,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1425895150/mkulqnabiczlpnzzqbkx.jpg',
    name: 'TravelBird',
  },
  {
    id: 64,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/p7dz8ijfj1zhz87agugt',
    name: 'Lesara',
  },
  {
    id: 65,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/lolvyvbzbsjs3thltlqr',
    name: 'Zego',
  },
  {
    id: 66,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/tbqtvrogujma9po7o7s0',
    name: 'Avocarrot',
  },
  {
    id: 67,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/sh6avywsplivj4v1qjso',
    name: 'Canadian Food Innovators',
  },
  {
    id: 68,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1489731524/cio973chwbdq1cxxsya9.png',
    name: 'Brite Energy Solar Holdings',
  },
  {
    id: 69,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1501527737/vwgd9jx3s7mojcchvcig.png',
    name: 'DealDey Limited',
  },
  {
    id: 70,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1441081778/jlfdxepvyzhdc7dj1cjd.png',
    name: 'Calibrium',
  },
  {
    id: 71,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/calpm7yibpcvtrj7jy9s',
    name: 'DiNovA Medtech',
  },
  {
    id: 72,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/olf1krch54e7wzuroez2',
    name: 'Now Healthcare Group',
  },
  {
    id: 73,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/rxwa4wr3pldpotzzye0j',
    name: 'Invi Labs',
  },
  {
    id: 74,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1412013470/wgyq80fxvsejpi11wt3x.png',
    name: 'Parcel',
  },
  {
    id: 75,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ompqomo7ozgykljoerv8',
    name: 'Driven Deliveries',
  },
  {
    id: 76,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/we1fowww1rhu5z5ksb47',
    name: 'eCooltra',
  },
  {
    id: 77,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1446179781/mjci0wrqafp5dgevmr3m.png',
    name: 'Suncrest Solar',
  },
  {
    id: 78,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1438235560/tejotplbrzjczfxnkccu.png',
    name: 'Schoolguru',
  },
  {
    id: 79,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1410148869/baxxafxly1hzrwzg1ha4.png',
    name: 'IndianRoots',
  },
  {
    id: 80,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/498ccdc8ae8c8ab195a3',
    name: 'Demin',
  },
  {
    id: 81,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397195178/3c7e8441ae7c7a732d8061e0a1755e84.png',
    name: 'FraudMetrix',
  },
  {
    id: 82,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/vt6awxztoxioxvzxenxr',
    name: 'On the dot',
  },
  {
    id: 83,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/abfacblc18mad9mo2x1y',
    name: 'Mercado Bitcoin',
  },
  {
    id: 84,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/326b0f1d36438135fab1',
    name: 'OMusic',
  },
  {
    id: 85,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1466014254/of5gidhjmlwplefmt5w3.png',
    name: 'Ellation',
  },
  {
    id: 86,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/8306c89c0e9fd7cd2171',
    name: 'TrafficData',
  },
  {
    id: 87,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/weacxyjj1wrdl7fq0uwp',
    name: 'Innovate Services',
  },
  {
    id: 88,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1499941388/uqiczhykmwsun3xmxkis.png',
    name: 'Building Energy',
  },
  {
    id: 89,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/zcuwx2o5pjcjroefprff',
    name: 'Mobisol',
  },
  {
    id: 90,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/oruhzkie3xykieuzkkbi',
    name: 'Muni Tienda',
  },
  {
    id: 91,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/nszqabt2x0qybtpw212m',
    name: 'Bidderlo',
  },
  {
    id: 92,
    pic: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/couziv7xadea1lvqclvz',
    name: 'UKCloud Health',
  },
];

export default partners;
